var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-item",
      staticStyle: { position: "relative", cursor: "pointer" },
    },
    [
      _c("div", {}, [
        _c(
          "div",
          { staticStyle: { padding: "5px 5px", "background-color": "#fff" } },
          [
            _c("el-image", {
              staticStyle: { width: "200px", height: "150px" },
              attrs: {
                src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { padding: "5px 5px", "background-color": "#fff" } },
          [
            _c("div", { staticClass: "flex jia-ge" }, [
              _c("div", { style: { color: _vm.texTStyleData.bgColor } }, [
                _c("span", [_vm._v("￥56")]),
                _c("span", { staticStyle: { "font-size": "8px" } }, [
                  _vm._v("/6寸"),
                ]),
              ]),
              _vm._m(0),
            ]),
            _vm._m(1),
            _vm._m(2),
          ]
        ),
        _vm._m(3),
        _c(
          "div",
          {
            staticStyle: {
              margin: "10px 0",
              padding: "5px 5px",
              "background-color": "#fff",
            },
          },
          [
            _vm._m(4),
            _c("div", [
              _c(
                "div",
                { staticStyle: { "background-color": "#fff" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "200px", height: "150px" },
                    attrs: {
                      src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticStyle: {
            padding: "5px 5px",
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "35px",
            "background-color": "#fff",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: {
                height: "100%",
                "justify-content": "space-between",
                "align-items": "center",
              },
            },
            [
              _c("span", { staticClass: "font-8" }, [_vm._v("收藏")]),
              _c("span", { staticClass: "font-8" }, [_vm._v("购物车")]),
              _c("span", { staticClass: "font-8" }, [_vm._v("会员卡")]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "jia-ru",
                    style: {
                      backgroundColor: _vm.themeStyleData.textColor,
                      color: [
                        "#2f2f34",
                        "#70b05d",
                        "#9a875f",
                        "#ff547b",
                        "#65c4aa",
                        "#63be72",
                        "#4a90e2",
                        "#c3a769",
                        "#2f2f34",
                        "#884cff",
                        "#FC3F3F",
                      ].includes(_vm.themeStyleData.bgColor)
                        ? _vm.themeStyleData.bgColor
                        : "#fff",
                    },
                  },
                  [_vm._v("加入购物车")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "gou-wu",
                    style: { backgroundColor: _vm.themeStyleData.bgColor },
                  },
                  [_vm._v("立即购买")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "font-size": "8px" } }, [
      _c("span", [_vm._v("库存94")]),
      _c("span", [_vm._v("销量8")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          margin: "10px 0",
          "font-size": "8px",
          color: "red",
          "text-align": "right",
        },
      },
      [_c("span", [_vm._v("领卷>")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex",
        staticStyle: {
          "justify-content": "space-between",
          margin: "10px 0",
          "align-items": "end",
        },
      },
      [
        _c("span", { staticStyle: { "font-weight": "700" } }, [
          _vm._v("提拉米苏"),
        ]),
        _c("span", { staticStyle: { "font-size": "8px" } }, [_vm._v("分享")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          margin: "10px 0",
          padding: "5px 5px",
          "background-color": "#fff",
          "border-radius": "8px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: {
              "justify-content": "space-between",
              "align-items": "end",
            },
          },
          [
            _c("span", { staticStyle: { color: "#ccc" } }, [_vm._v("选择")]),
            _c("span", { staticStyle: { "font-size": "12px" } }, [
              _vm._v("请选择商品规格>"),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex",
        staticStyle: {
          "justify-content": "space-between",
          "align-items": "end",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-weight": "700", "font-size": "14px" } },
          [_vm._v("商品详情")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }