var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "bg-Color marB10 x-f-end padd15" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.setafterSalesSettings },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10",
        attrs: { cardTitle: "到货设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15 x-f fontColor" },
                  [
                    _c("span", [
                      _vm._v("请选择发货状态后，自动确认到货的时间，请填写"),
                    ]),
                    _c("el-input", {
                      staticClass: "marL10",
                      staticStyle: { width: "180px" },
                      attrs: { type: "number" },
                      model: {
                        value:
                          _vm.form.mall_SYSTEM_CONFIG_AFTER_SALES_SETTINGS_11,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "mall_SYSTEM_CONFIG_AFTER_SALES_SETTINGS_11",
                            $$v
                          )
                        },
                        expression:
                          "form.mall_SYSTEM_CONFIG_AFTER_SALES_SETTINGS_11",
                      },
                    }),
                    _c("span", { staticClass: "marL10" }, [_vm._v("天")]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }