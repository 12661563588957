import request from '@/utils/request'

// // 商城
// export function gettHemeColor() {
//     return request({
//         url: '/api/system/mall/config/style/getDetail',
//         method: 'get'
//     })
// }
// 商城
export function gettHemeColor() {
    return request({
        url: '/api/system/mall/config/style/list',
        method: 'get'
    })
}
// 商城
export function configStyleSave(params) {
    return request({
        url: '/api/system/mall/config/style/save',
        method: 'post',
        data: params
    })
}

// 商城
export function upHemeColor(params) {
    return request({
        url: '/api/system/mall/config/style/updateOrSave',
        method: 'post',
        data: params
    })
}
// 商城
export function updateIsUse(params) {
    return request({
        url: '/api/system/mall/config/style/updateIsUse',
        method: 'put',
        data: params
    })
}