<template>
    <el-dialog
        title="提示"
        :visible.sync="showShopDialog"
        width="70%"
        :before-close="close"
    >
       <div style="width: 100%" v-loading="loading">
           <el-table border :data="shopList" style="width: 100%" @selection-change="handleSelectionChange">
               <el-table-column type="selection" width="55"></el-table-column>
               <el-table-column prop="shopNo" label="编号" width="180"></el-table-column>
               <el-table-column prop="shopName" label="名称" width="180"></el-table-column>
               <el-table-column prop="shopTelephone" label="电话" width="180"></el-table-column>
               <el-table-column prop="shopAddress" label="地址"></el-table-column>
           </el-table>
          <div class="x-f-end padd10">
              <el-pagination
                  background
                  @current-change="getList"
                  :current-page.sync="queryParams.pageNum"
                  :page-size.sync="queryParams.pageSize"
                  layout="prev, pager, next"
                  :total="total">
              </el-pagination>
          </div>
       </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>

</template>

<script>
import { shopInfoList } from '@/api/O2OMall/manage/shop'

export default {
    name: 'selectShop',
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        showShopDialog: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            queryParams:{
                pageNum:1,
                pageSize:10,
                status: 0, // 已上架的
            },
            loading: false,
            shopList: [],
            total: 0,
            selectData: [],
        }
    },
    created() {
        this.getList()
    },
    methods: {
        /** 查询门店列表 */
        getList() {
            this.loading = true
            shopInfoList(this.queryParams).then(response => {
                this.shopList = response.rows
                this.total = response.total
                this.loading = false
            })
        },
        handleSelectionChange(val){
            this.selectData = val
        },
        close(){
            this.$emit('close')
        },
        submit(){
            this.$emit('getShop',  this.selectData)
        }
    }
}
</script>

<style scoped>

</style>
