//分类组件
export const categoryPage = [
    {
      name: 'fuwufangshi',
      icon: 'fuwufangshi',
      text: '服务方式',
      cursor: false,
      maximum: 1,
      num: 0,
      place: ['categoryPage']
    },
    {
      name: 'shangpindaohang',
      icon: 'shangpinliebiao',
      text: '商品导航',
      cursor: false,
      maximum: 1,
      num: 0,
      place: ['categoryPage']
    }
  ]