var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "提示",
        visible: _vm.showShopDialog,
        width: "70%",
        "before-close": _vm.close,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showShopDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.shopList },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { prop: "shopNo", label: "编号", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "shopName", label: "名称", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "shopTelephone", label: "电话", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "shopAddress", label: "地址" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "x-f-end padd10" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.queryParams.pageNum,
                  "page-size": _vm.queryParams.pageSize,
                  layout: "prev, pager, next",
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.getList,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }