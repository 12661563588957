//个人中心组件
export const personalCenter =  [
  {
    name: 'plusvip',
    icon: 'plusVip',
    text: 'PLUS会员',
    cursor: false,
    maximum: 1,
    num: 0,
    place: ['personalCenter']
  },
  {
    name: 'huiyuanxinxi',
    icon: 'me',
    text: '会员信息',
    cursor: false,
    maximum: 1,
    num: 0,
    place: ['personalCenter']
  },
  {
    name: 'liebiaodaohang',
    icon: 'liebiaodaohang',
    text: '列表导航',
    cursor: false,
    maximum: 5,
    num: 0,
    place: ['personalCenter']
  },
  {
    name: 'tubiaozu',
    icon: 'tubiaozu',
    text: '图标组',
    cursor: false,
    maximum: 5,
    num: 0,
    place: ['personalCenter']
  },
  {
    name: 'anniuzu',
    icon: 'anniuzu2',
    text: '按钮组',
    cursor: false,
    maximum: 5,
    num: 0,
    place: ['personalCenter']
  }
]