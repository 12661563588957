<template>
    <div>
        <div class="bg-Color marB10 x-f-end padd15">
            <el-button type="primary" size="mini" @click="freightSettingsSettings">保存</el-button>
        </div>
        <cardTitleCom cardTitle="计费规则" class="bg-Color marT10">
            <template #cardContent>
                <div class="padd15 y-start fontColor">
                    <div>
                        <div class="x-f">
                            <span>按件数计算运费规则</span>
                            <el-tooltip class="item marL20" effect="dark" :content="content1" placement="top">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="marT10">
                            <el-radio-group v-model="form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_11">
                                <el-radio label="1">相同模板计算累加</el-radio>
                                <el-radio label="2">单个商品计算累加</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="marT20">
                        <div class="x-f">
                            <span>按重量计算运费规则</span>
                            <el-tooltip class="item marL20" effect="dark" :content="content2" placement="top">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="marT10">
                            <el-radio-group v-model="form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_12">
                                <el-radio label="1">相同模板计算累加</el-radio>
                                <el-radio label="2">单个商品计算累加</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="marT20">
                        <div class="x-f">
                            <span>按金额比例计算运费规则</span>
                            <el-tooltip class="item marL20" effect="dark" :content="content3" placement="top">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="marT10">
                            <el-radio-group v-model="form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_13">
                                <el-radio label="1">相同模板计算累加</el-radio>
                                <el-radio label="2">单个商品计算累加</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
            </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="商城运费" class="bg-Color marT10">
            <template #cardContent>
                <div class="padd15 fontColor">
                    <div>
                        <span>请选择运费对应的客户承担费用</span>
                        <div class="x-f marT20">
                            <span style="width: 80px">费用类型</span>
                            <select-frame :tableData="tableData" v-model="form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_21"
                                          suffixIcon="el-icon-arrow-down" @selectRowObj="selectRowObj"
                            />
                        </div>
                    </div>
                    <div class="marT20">
                        <span>整单包邮设置</span>
                        <el-table
                            class="marT10"
                            border
                            ref="tableData1"
                            @cell-mouse-enter="cellMouseEnter"
                            @cell-mouse-leave="cellMouseLeave"
                            width="100%"
                            :data="mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22"
                        >
                            <el-table-column width="80" align="center">
                                <template v-slot="scope">
                                    <i
                                        v-if="scope.row.hoverRow"
                                        @click="row('push', scope.$index)"
                                        class="el-icon-circle-plus operatePush"
                                    ></i>
                                    <i
                                        v-if="scope.row.hoverRow"
                                        @click="row('del', scope.$index)"
                                        class="el-icon-remove operateDel"
                                    ></i>
                                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="区域" align="center" width="260">
                                <template v-slot="scope">
                                    <span style="width: 120px" class="omit">{{ scope.row.region }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="满包邮金额" prop="amount" align="center" width="180">
                                <template v-slot="scope">
                                    <el-input style="width: 100px" :min="min" v-model="scope.row.amount" size="mini" @change="aaaa($event)"
                                              onkeypress="return (/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8" type="number"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="设置区域" align="center" width="180">
                                <template v-slot="scope">
                                    <el-button :disabled="!isClick" type="text" @click="openDialog(scope.$index)">设置
                                    </el-button>
                                </template>
                            </el-table-column>
                            <el-table-column></el-table-column>
                        </el-table>
                    </div>
                </div>
            </template>
        </cardTitleCom>

        <el-dialog
            title="提示"
            :visible.sync="regionVisible"
            width="700px"
            :before-close="close"
        >
            <div>
                <el-table
                    :data="regionData"
                    height="300"
                    border
                    tooltip-effect="dark"
                    ref="dialogTable"
                    :row-key="getRowKeys"
                    @selection-change="onContactSelectChange"
                    style="width: 100%"
                >
                    <el-table-column type="selection" align="center" width="55"></el-table-column>
                    <el-table-column prop="id" align="center" label="编码" width="100"></el-table-column>
                    <el-table-column prop="label" align="center" label="名称"></el-table-column>
                    <el-table-column label="行政级次" align="center">
                        <template v-slot="scope">省</template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="dialogOk">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom/index.vue'
import SelectFrame from '@/views/components/SelectFrame/index.vue'

import {
    getMallFreightSettingsConfigDetail,
    saveUpMallFreightSettingsConfig
} from '@/api/O2OMall/config/sysConfig'
import { getAllRegion } from '@/api/goods/region'

export default {
    name: 'freightConfiguration',
    components: {
        cardTitleCom,
        SelectFrame
    },
    data() {
        return {
            regionVisible: false, // 地区选择框
            regionData: [], // 地区列表

            content1: '问号展示内容案件数（相同模板计算累加:相同运费模板的商品进行件数累加计算运费。 单个商品计算累加: 每件商品单独计算运费后，合计作为单据运费。）',
            content2: '按重量（相同模板计算累加: 相同运费模板的商品进行重里累加计算运费后，合计作为单据运费。 单个商品计算累加:每单商品单独计算运费后，合计作为单据运费。）',
            content3: '按金额（相同模板计算累加:相同运费模板的商品进行件数累加计算运费。 单个商品计算累加: 每件商品单独计算运费后，合计作为单据运费。）',

            tableData: [
                { No: '001', Name: '运费' },
                { No: '002', Name: '其他费用' }
            ],
            min: 1,
            form: {
                mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_21: "",
                mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22: [],
                mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_11: "",
                mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_12: "",
                mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_13: ""

            },
            mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22: [{
                amount: 0,
                region: '',
                code: '',
            }],
            //禁用标识
            disabled: false,
            expenseType: '',
            selectTabList: [], // 地区列表选择
            mallSystemPostalsItem: 0, // 编辑列表行下标
            regionList: [],
            isClick: false // 是否可以选择行政区

            //     --------------------

        }
    },
    created() {
        this.getTreeselect()
        this.getDetails()
    },
    methods: {
        //获取全部行政地区事件
        getTreeselect() {
            getAllRegion().then(response => {
                response.data.forEach(item => {
                    this.regionData.push({
                        id: item.id,
                        label: item.label
                    })
                })

                this.isClick = true
            })
        },
        // 提交
        freightSettingsSettings() {

            // this.form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22 = this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22
            saveUpMallFreightSettingsConfig(this.form).then(res => {
                this.$message.success(res.msg)
                this.getDetails()
            })
        },
        //  获取页面详情
        getDetails() {
            getMallFreightSettingsConfigDetail().then(res => {
                if (res.data != null) {
                    this.form = res.data
                    // 处理费用类型回显
                    this.tableData.forEach(item => {
                        if (item.No == this.form.expenseType) {
                            this.expenseType = item.Name
                        }
                    })


                    if (res.data.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22!= null) {
                        this.form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22 = res.data.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22
                        this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22 = res.data.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22
                    }

                    console.log('详情数据：', this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22)
                }

            })
        },
        row(type, index) {
            if (type == 'push') {
                this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22.splice(index + 1, 0, {})
            } else {
                if (this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22.length <= 1) return
                this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22.splice(index, 1)
            }
        },
        // 费用类型选择
        selectRowObj(val) {
            // console.log('当前行：', val)
            this.expenseType = val.Name
            this.form.expenseType = val.No
        },
        // 打开弹框，选择省地区
        openDialog(index) {
            console.log('第' + index + '行', this.mallSystemPostalsItem)
            this.mallSystemPostalsItem = index
            this.regionVisible = true
            if( this.mallSystemPostalsItem == index){
                this.$nextTick(() => {
                    let regL = this.regionData.length
                    for (let i = 0; i < regL; i++) {
                        console.log('回显==', this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22[index].code, this.regionData[i].id)
                        if (this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22[index].code.indexOf(this.regionData[i].id) != -1) {
                            // this.$nextTick(() => {
                            this.$refs.dialogTable.toggleRowSelection(this.regionData[i], true)
                            // })
                        } else {
                            // this.$nextTick(() => {
                            this.$refs.dialogTable.toggleRowSelection(this.regionData[i], false)
                            // })
                        }
                    }
                })
            }



        },
        // 弹框确定按钮
        dialogOk() {
            let region = []
            let code = []
            this.selectTabList.forEach(item => {
                region.push(item.label)
                code.push(item.id)
            })
            this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22[this.mallSystemPostalsItem].region = region.join(',')
            this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22[this.mallSystemPostalsItem].code = code.join(',')

            console.log('列表：', this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22)
            this.close()
            this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22.forEach(item => {
                let obj = {
                    amount: item.amount,
                    code: item.code,
                    region: item.region
                }
                console.log('列表信息：', obj)
                this.form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22.push(obj)
            })
        },
        getRowKeys(row) {
            return row.id
        },
        close() {
            this.selectTabList = []
            this.regionVisible = false
        },
        // 弹框列表数据选择
        onContactSelectChange(val) {
            this.selectTabList = val
            console.log('选择的数据：', this.selectTabList)
        },
        //表格行hover时,显示操作加减号
        cellMouseEnter(row) {
            //获取当前行下标
            let eleIndex = this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22.indexOf(row)
            // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
            let Arr = JSON.parse(JSON.stringify(this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22))
            for (let index = 0; index < Arr.length; index++) {
                const element = Arr[index]
                if (eleIndex == index) {
                    element['hoverRow'] = true
                } else {
                    element['hoverRow'] = false
                }
            }
            this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22 = JSON.parse(JSON.stringify(Arr))
        },
        //表格行离开hover时,不显示操作加减号,显示序号
        cellMouseLeave() {
            // 移除hover的事件
            for (let index = 0; index < this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22.length; index++) {
                const element = this.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22[index]
                element['hoverRow'] = false
            }
        },
        aaaa(e){
            console.log('2121:', e)
        }
    }
}
</script>

<style scoped>

</style>
