<template>
    <div>
        <div class="bg-Color marB10 x-f-end padd15">
            <el-button type="primary" size="mini" @click="getliveBroadcastSettings">保存</el-button>
        </div>
        <cardTitleCom cardTitle="小程序直播设置" class="bg-Color marT10">
            <template #cardContent>
                <div class="padd15 fontColor">
                    <div class="x-f">
                        <span>开启小程序直播服务</span>
                        <el-switch
                            class="marL20"
                            v-model="form.mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_11">
                        </el-switch>
                    </div>
                    <div class="x-f marT10">
                        注: 仅支持小程序订货商城可开启小程序直播入口，开启服务前需在微信小程序后台开通直播功能，操作指引清参考:
                        <a href="##" style="color: #1c75f4" class="marL10">小程序商城直播开启指引</a>
                    </div>
                    <div class="x-f marT10">
                        <span>直播间选择模式</span>
                       <div class="marL20">
                           <el-radio v-model="form.mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12" label="1">自动选取待直播或正在直播的房间</el-radio>
                           <el-radio v-model="form.mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12" label="2">手动选择直播房间</el-radio>
                       </div>
                    </div>
                </div>
            </template>
        </cardTitleCom>
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom/index.vue'
import { getMallLiveBroadcastSettingsDetail, saveUpMallLiveBroadcastSettingsConfig } from '@/api/O2OMall/config/sysConfig'

export default {
    name: 'liveSetting',
    components: {
        cardTitleCom
    },
    data(){
        return{
            form: {
                mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_11: false,
                mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12: ''
            }
        }
    },
    created() {
        // this.getDetails()
    },
    methods:{
        getDetails(){
            getMallLiveBroadcastSettingsDetail().then(res => {
                console.log('交易：',res)
                this.form = res.data
                // this.form.liveStatus = liveStatus
                // this.form.liveMode = liveMode
            })
        },
        getliveBroadcastSettings(){
            console.log('----', this.form)
            saveUpMallLiveBroadcastSettingsConfig(this.form).then(res => {
                this.$message.success(res.msg)
                this.getDetails()
            })
        }
    }
}
</script>

<style scoped>

</style>
