var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-item",
      staticStyle: { position: "relative", cursor: "pointer" },
    },
    [
      _c("div", { staticStyle: { position: "relative" } }, [
        _c(
          "div",
          { staticStyle: { padding: "5px 5px", "background-color": "#fff" } },
          [
            _c("el-image", {
              staticStyle: { width: "200px", height: "150px" },
              attrs: {
                src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { padding: "5px 5px", "background-color": "#fff" } },
          [
            _c("div", { staticClass: "flex jia-ge" }, [
              _c("div", { style: { color: _vm.texTStyleData.bgColor } }, [
                _c("span", [_vm._v("￥56")]),
                _c("span", { staticStyle: { "font-size": "8px" } }, [
                  _vm._v("/6寸"),
                ]),
              ]),
              _vm._m(0),
            ]),
            _vm._m(1),
            _vm._m(2),
          ]
        ),
        _vm._m(3),
        _c(
          "div",
          {
            staticStyle: {
              margin: "10px 0",
              padding: "5px 5px",
              "background-color": "#fff",
            },
          },
          [
            _vm._m(4),
            _c("div", [
              _c(
                "div",
                { staticStyle: { "background-color": "#fff" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "200px", height: "150px" },
                    attrs: {
                      src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("div", {
          staticStyle: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            "background-color": "#ccc",
            opacity: "0.5",
          },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "tc-box",
          staticStyle: {
            padding: "5px 5px",
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "300px",
            "background-color": "#fff",
          },
        },
        [
          _vm._m(5),
          _c("div", [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("el-image", {
                  staticStyle: { width: "70px", height: "70px" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    staticStyle: {
                      "flex-flow": "column",
                      "justify-content": "space-between",
                      "margin-left": "10px",
                      flex: "1",
                    },
                  },
                  [
                    _c("span", { staticStyle: { "font-weight": "700" } }, [
                      _vm._v("提拉米苏"),
                    ]),
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v("库存: 94"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "justify-content": "space-between" },
                      },
                      [
                        _c(
                          "span",
                          { style: { color: _vm.texTStyleData.bgColor } },
                          [_vm._v("￥56")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: { "align-items": "center" },
                          },
                          [
                            _c("div", { staticClass: "yq-box" }, [_vm._v("-")]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "10px",
                                  margin: "0 5px",
                                },
                              },
                              [_vm._v("1")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "yq-box",
                                style: {
                                  backgroundColor: _vm.themeStyleData.bgColor,
                                  color: "#fff",
                                },
                              },
                              [_vm._v("+")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", [
              _c(
                "div",
                { staticStyle: { "font-weight": "700", margin: "7px 0" } },
                [_vm._v("规格")]
              ),
              _c(
                "div",
                {
                  staticClass: "fk-box",
                  style: {
                    backgroundColor: _vm.themeStyleData.bgColor + "30",
                    color: _vm.themeStyleData.bgColor,
                    width: "31px",
                  },
                },
                [_vm._v("6寸")]
              ),
              _c(
                "div",
                { staticStyle: { "font-weight": "700", margin: "7px 0" } },
                [_vm._v("甜度")]
              ),
              _vm._m(6),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "flex qr",
              style: { backgroundColor: _vm.themeStyleData.bgColor },
            },
            [_c("div", { style: {} }, [_vm._v("确认")])]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "font-size": "8px" } }, [
      _c("span", [_vm._v("库存94")]),
      _c("span", [_vm._v("销量8")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          margin: "10px 0",
          "font-size": "8px",
          color: "red",
          "text-align": "right",
        },
      },
      [_c("span", [_vm._v("领卷>")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex",
        staticStyle: {
          "justify-content": "space-between",
          margin: "10px 0",
          "align-items": "end",
        },
      },
      [
        _c("span", { staticStyle: { "font-weight": "700" } }, [
          _vm._v("提拉米苏"),
        ]),
        _c("span", { staticStyle: { "font-size": "8px" } }, [_vm._v("分享")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          margin: "10px 0",
          padding: "5px 5px",
          "background-color": "#fff",
          "border-radius": "8px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: {
              "justify-content": "space-between",
              "align-items": "end",
            },
          },
          [
            _c("span", { staticStyle: { color: "#ccc" } }, [_vm._v("选择")]),
            _c("span", { staticStyle: { "font-size": "12px" } }, [
              _vm._v("请选择商品规格>"),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex",
        staticStyle: {
          "justify-content": "space-between",
          "align-items": "end",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-weight": "700", "font-size": "14px" } },
          [_vm._v("商品详情")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "text-align": "right" } }, [
      _c("i", { staticClass: "el-icon-close" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "fk-box" }, [_vm._v("少糖")]),
      _c(
        "div",
        {
          staticClass: "fk-box",
          staticStyle: {
            padding: "5px",
            "background-color": "#f1f1f1",
            "border-radius": "3px",
            margin: "0 5px",
          },
        },
        [_vm._v("标准糖")]
      ),
      _c("div", { staticClass: "fk-box" }, [_vm._v("多糖")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }