<template>
    <div class="box-item" style="position: relative;cursor: pointer;">
        <div class="">
            <div style="padding: 5px 5px;background-color: #fff;">
                <el-image style="width: 200px;height: 150px;"
                    src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png"></el-image>
            </div>
            <div style="padding: 5px 5px;background-color: #fff;">
                <div class="flex jia-ge">
                    <div :style="{color: texTStyleData.bgColor }">
                        <span>￥56</span>
                        <span style="font-size: 8px;">/6寸</span>
                    </div>
                    <div style="font-size: 8px;">
                        <span>库存94</span>
                        <span>销量8</span>
                    </div>
                </div>
                <div style="margin: 10px 0;font-size: 12px;color: red;font-size: 8px;text-align: right;">
                    <span>领卷></span>
                </div>
                <div class="flex" style="justify-content: space-between;margin: 10px 0;align-items: end;">
                    <span style="font-weight: 700;">提拉米苏</span>
                    <span style="font-size: 8px;">分享</span>
                </div>
            </div>
            <div style="margin: 10px 0;padding: 5px 5px;background-color: #fff;border-radius: 8px;">
                <div class="flex" style="justify-content: space-between;align-items: end;">
                    <span style="color: #ccc;">选择</span>
                    <span style="font-size: 12px;">请选择商品规格></span>
                </div>
            </div>
            <div style="margin: 10px 0;padding: 5px 5px;background-color: #fff;">
                <div class="flex" style="justify-content: space-between;align-items: end;">
                    <span style="font-weight: 700;font-size: 14px;">商品详情</span>
                </div>
                <div>
                    <div style="background-color: #fff;">
                        <el-image style="width: 200px;height: 150px;"
                            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png"></el-image>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 5px 5px;position: absolute;bottom: 0;left: 0;width: 100%;height: 35px;background-color: #fff;">
            <div class="flex" style="height: 100%;justify-content: space-between;align-items: center;">
                <span class="font-8">收藏</span>
                <span class="font-8">购物车</span>
                <span class="font-8">会员卡</span>
                <div class="flex">
                    <div class="jia-ru" :style="{ backgroundColor: themeStyleData.textColor, color: ['#2f2f34', '#70b05d', '#9a875f', '#ff547b' , '#65c4aa', '#63be72', '#4a90e2', '#c3a769', '#2f2f34', '#884cff','#FC3F3F'].includes(themeStyleData.bgColor)? themeStyleData.bgColor : '#fff'}">加入购物车</div>
                    <div class="gou-wu" :style="{ backgroundColor: themeStyleData.bgColor }">立即购买</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'styleHome',
    components: {},
    props:{
        themeStyleData: {
            type: Object,
            default: { bgColor: '#fd5b34', textColor: '#ffeeec' }
        },
        texTStyleData: {
            type: Object,
            default: { bgColor: '#f62d21', textColor: '#FF9518' }
        }
    },
    data() {
        return {
        }
    },
    watch: {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
}

.box-item {
    border: 1px solid #f1f1f1;
    background-color: #f1f1f1;
}

.font-8 {
    font-size: 8px;
}

.jia-ru {
    font-size: 10px;
    padding: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #fff;
}

.gou-wu {
    color: #fff;
    font-size: 10px;
    padding: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.jia-ge {
    justify-content: space-between;
    margin: 10px 0;
    margin-top: 0;
    align-items: end;
}
</style>
