var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-item",
      staticStyle: { position: "relative", cursor: "pointer", width: "210px" },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "flex", staticStyle: { margin: "5px" } }, [
        _c("div", { staticClass: "jia-ru" }, [_vm._v("门店自提")]),
        _c(
          "div",
          {
            staticClass: "tc-box",
            style: { backgroundColor: _vm.themeStyleData.bgColor },
          },
          [_vm._v("同城配送")]
        ),
        _c("div", { staticClass: "gou-wu" }, [_vm._v("快递配送")]),
      ]),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "flex list-box", staticStyle: { margin: "7px 0" } },
        [
          _c("span", [_vm._v("配送时间")]),
          _c("div", { staticClass: "flex" }, [
            _c(
              "span",
              {
                style: { color: _vm.themeStyleData.bgColor, fontSize: "10px" },
              },
              [_vm._v("05-29(明天) 00:30-01:00")]
            ),
            _vm._m(2),
          ]),
        ]
      ),
      _c(
        "div",
        { staticStyle: { "background-color": "#fff", padding: "5px 2px" } },
        [
          _c("div", [_vm._v("商品信息")]),
          _c("div", { staticStyle: { margin: "10px 0" } }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("el-image", {
                  staticStyle: { width: "70px", height: "70px" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png",
                  },
                }),
                _vm._m(3),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: {
                "justify-content": "space-between",
                "margin-top": "10px",
              },
            },
            [
              _c("span", [_vm._v("商品金额")]),
              _c("span", { style: { color: _vm.texTStyleData.bgColor } }, [
                _vm._v("￥56"),
              ]),
            ]
          ),
          _vm._m(4),
          _vm._m(5),
          _c("el-divider"),
          _c(
            "div",
            { staticStyle: { "text-align": "right", padding: "5px" } },
            [
              _c(
                "span",
                {
                  staticStyle: { "font-size": "12px" },
                  style: { color: _vm.texTStyleData.bgColor },
                },
                [_vm._v("小计: ￥56")]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "background-color": "#fff",
            padding: "5px 2px",
            margin: "7px 0",
          },
        },
        [
          _c("div", [_vm._v("支付方式")]),
          _c("el-divider"),
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: {
                "justify-content": "space-between",
                "margin-top": "5px",
              },
            },
            [
              _c("span", [_vm._v("微信支付")]),
              _c(
                "div",
                {
                  staticClass: "qu-box",
                  style: { backgroundColor: _vm.themeStyleData.bgColor },
                },
                [_vm._v("√")]
              ),
            ]
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex qr", style: { backgroundColor: "#fff" } },
        [
          _c("div", [
            _c("span", [
              _vm._v("合计： "),
              _c("span", { style: { color: _vm.texTStyleData.bgColor } }, [
                _vm._v("￥56"),
              ]),
            ]),
            _c("span"),
          ]),
          _c(
            "div",
            {
              staticClass: "ri-btn",
              style: { backgroundColor: _vm.themeStyleData.bgColor },
            },
            [
              _c("span", { staticStyle: { color: "#fff" } }, [
                _vm._v("提交订单"),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c(
        "div",
        {
          staticClass: "flex",
          staticStyle: {
            height: "30px",
            "background-color": "#fff",
            width: "100%",
            "align-items": "center",
          },
        },
        [
          _c("i", { staticClass: "el-icon-arrow-left" }),
          _c("div", { staticStyle: { flex: "1", "text-align": "center" } }, [
            _vm._v("订单确认"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex list-box" }, [
      _c("div", [
        _c("div", [_vm._v("广东省深圳市宝安区")]),
        _c("div", { staticStyle: { "font-size": "10px" } }, [
          _vm._v("陈慕宇 17580469945"),
        ]),
      ]),
      _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-left": "5px" } }, [
      _vm._v("共1件"),
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex",
        staticStyle: {
          "justify-content": "space-between",
          "margin-top": "10px",
        },
      },
      [
        _c("span", [_vm._v("优惠券")]),
        _c("span", { staticStyle: { "font-size": "10px" } }, [
          _vm._v(" 2张可用"),
          _c("i", { staticClass: "el-icon-arrow-right" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex",
        staticStyle: {
          "justify-content": "space-between",
          "margin-top": "10px",
        },
      },
      [_c("span", [_vm._v("配送费")]), _c("span", [_vm._v("￥0")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }