var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "基础信息" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15 x-f fontColor" },
                  [
                    _c(
                      "el-form",
                      { attrs: { model: _vm.form, "label-width": "130px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商城名称：" } },
                          [
                            _c("el-input", {
                              staticClass: "inputW260",
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.form.mallName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "mallName", $$v)
                                },
                                expression: "form.mallName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商城Logo：" } },
                          [
                            _c("AccessoryUpload", {
                              attrs: {
                                listType: "picture-card",
                                size: "mini",
                                title: "",
                                limit: 1,
                                fileList: _vm.fileList,
                                noneBtnImg: _vm.form.mallLogoUrl != "",
                              },
                              on: {
                                getFileItems: _vm.getFileItems,
                                delFileItems: _vm.deleteImg,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客服电话：" } },
                          [
                            _c("el-input", {
                              staticClass: "inputW260",
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.form.serviceTelephone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "serviceTelephone", $$v)
                                },
                                expression: "form.serviceTelephone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.setSave },
                              },
                              [_vm._v("保 存")]
                            ),
                          ],
                          1
                        ),
                        _vm.form.mobileLink != null
                          ? _c("el-divider")
                          : _vm._e(),
                        _vm.form.mobileLink != null
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "移动端链接：" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "span",
                                      { attrs: { id: "mobileLink" } },
                                      [_vm._v(_vm._s(_vm.form.mobileLink))]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "marL10",
                                        attrs: { size: "mini", type: "text" },
                                        on: { click: _vm.copyUrl },
                                      },
                                      [_vm._v("复制链接")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.form.previewQrCodeUr != null
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "商城二维码：" } },
                              [
                                _c("el-image", {
                                  staticClass: "img pointer",
                                  attrs: {
                                    "preview-src-list": _vm.srcList,
                                    src: _vm.form.previewQrCodeUr,
                                  },
                                  on: { click: _vm.vbs },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }