<template>
    <div>
        <div class="bg-Color marB10 x-f-end padd15">
            <el-button type="primary" size="mini" @click="setMallInformation">保存</el-button>
        </div>
        <el-form :inline="false" :model="form" class="marT10" :rules="rules" ref="formRef">
            <cardTitleCom cardTitle="品牌信息">
                <template #cardContent>
                    <div class="padd15">
                        <span class="">品牌信息将在商城主页面的页尾进行展示，优先展示品牌logo</span>
                        <el-form-item label="品牌名称" label-width="80px" prop="mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_11" class="marT10">
                            <el-input v-model="form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_11"
                                      placeholder="请输入内容"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label-width="10px">
                            <accessory-upload
                                listType="picture-card" :fileList="fileList" :limit="1" title="品牌logo"
                              :noneBtnImg="form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12 != '' && form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12 != null"
                              explain="仅支持格式JPG、PNG，建议尺寸330*90" @getFileItems="getFileItems"
                              @delFileItems="deleteImg"
                            ></accessory-upload>

                        </el-form-item>
                    </div>
                </template>
            </cardTitleCom>
            <cardTitleCom cardTitle="门店商城负责人信息">
                <template #cardContent>
                    <div class="padd15">
                        <span class="">为了方便顾客联系您，请填写门店商城负责人姓名和联系方式</span>
                        <el-form-item label="姓名" label-width="80px"
                                      prop="mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_21" class="marT10"
                        >
                            <el-input v-model="form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_21"
                                      placeholder="请输入内容"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式" prop="mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_22"
                                      label-width="80px"
                        >
                            <el-input v-model="form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_22"
                                      placeholder="请输入内容"
                            ></el-input>
                        </el-form-item>
                    </div>
                </template>
            </cardTitleCom>
        </el-form>
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import AccessoryUpload from '@/views/components/accessoryUpload/index.vue'
import {
    getMallSysSettings, saveOrUpdateMallSysSettings
} from '@/api/O2OMall/config/sysConfig'

export default {
    name: 'shoppengMessage',
    components: {
        cardTitleCom,
        AccessoryUpload
    },
    data() {
        // 自定义验证
        const validPhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入电话号码'))
            } else if (!this.isvalidPhone(value)) { //判断用户输入的值是否符合规则
                callback(new Error('请输入正确的11位手机号码'))
            } else {
                callback()
            }
        }
        return {
            form: {
                mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_11: '',
                mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12: '',
                mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_21: '',
                mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_22: ''
            },
            rules: {
                mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_21: [
                    { required: true, message: '请输入负责人姓名', trigger: 'blur' }
                ],
                mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_22: [
                    { required: true, validator: validPhone, trigger: 'blur' }
                ],
                mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_11: [
                    { required: true, message: '请输入品牌名称', trigger: 'blur' }
                ]
            },
            fileList: []
        }
    },
    created() {
        this.getDetails()
    },
    methods: {
        getDetails() {
            getMallSysSettings().then(res => {
                if (res.data != null) {
                    this.fileList = []
                    this.form = res.data
                    if (res.data.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12 != '') {
                        this.fileList.push({
                            url: res.data.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12
                        })
                    }
                }
            })
        },
        setMallInformation() {
            console.log('商城信息', this.form)
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    if (this.form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12 == '' || !this.form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12) {
                        return this.$message.warning('请上传图片')
                    }
                    saveOrUpdateMallSysSettings(this.form).then(res => {
                        this.$message.success(res.msg)
                    })
                    this.getDetails()
                } else {
                    console.log('error submit!!')
                    return false
                }
            })

        },
        // 图片上传成功
        getFileItems(file) {
            console.log('图片：', file)
            this.form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12 = file.accessUrl
            // this.form.fileSize = file.fileSize
            // this.form.fileName = file.fileName
        },
        // 删除logo
        deleteImg() {
            this.form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12 = ''
        },
        isvalidPhone(phone) {
            const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
            return reg.test(phone)
        }
    }
}
</script>

<style scoped>

</style>
