<template>
    <div class="box-item" style="position: relative;cursor: pointer;width: 210px;">
        <div class="">
            <div class="flex" style="height: 30px;background-color: #fff;width: 100%;align-items: center;">
                <i class="el-icon-arrow-left"></i>
                <div style="flex: 1;text-align: center;">订单确认</div>
            </div>
        </div>
        <div class="flex" style="margin: 5px;">
            <div class="jia-ru">门店自提</div>
            <div class="tc-box" :style="{ backgroundColor: themeStyleData.bgColor }">同城配送</div>
            <div class="gou-wu">快递配送</div>
        </div>
        <div class="flex list-box" style="">
            <div>
                <div>广东省深圳市宝安区</div>
                <div style="font-size: 10px;">陈慕宇 17580469945</div>
            </div>
            <div>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <div class="flex list-box" style="margin: 7px 0">
            <span>配送时间</span>
            <div class="flex">
                <span :style="{ color: themeStyleData.bgColor, fontSize: '10px' }">05-29(明天) 00:30-01:00</span>
                <div>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <div style="background-color: #fff;padding: 5px 2px;">
            <div>商品信息</div>
            <div style="margin: 10px 0;">
                <div class="flex">
                    <el-image style="width: 70px;height: 70px;"
                        src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png"></el-image>
                    <div style="margin-left: 5px;">共1件<i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
            <div class="flex" style="justify-content: space-between;margin-top: 10px;">
                <span>商品金额</span>
                <span :style="{color: texTStyleData.bgColor}">￥56</span>
            </div>
            <div class="flex" style="justify-content: space-between;margin-top: 10px;">
                <span>优惠券</span>
                <span style="font-size: 10px;">
                    2张可用<i class="el-icon-arrow-right"></i>
                </span>
            </div>
            <div class="flex" style="justify-content: space-between;margin-top: 10px;">
                <span>配送费</span>
                <span>￥0</span>
            </div>
            <el-divider></el-divider>
            <div style="text-align: right;padding: 5px;">
                <span style="font-size: 12px;" :style="{color: texTStyleData.bgColor}">小计: ￥56</span>
            </div>
        </div>
        <div style="background-color: #fff;padding: 5px 2px;margin: 7px 0">
            <div>支付方式</div>
            <el-divider></el-divider>
            <div class="flex" style="justify-content: space-between;margin-top: 5px;">
                <span>微信支付</span>
                <div class="qu-box" :style="{ backgroundColor: themeStyleData.bgColor }">√</div>
            </div>
            <el-divider></el-divider>
        </div>
        <div class="flex qr" :style="{ backgroundColor: '#fff' }">
                <div>
                    <span>合计：
                        <span :style="{color: texTStyleData.bgColor}">￥56</span>
                    </span>
                    <span></span>
                </div>
                <div class="ri-btn" :style="{ backgroundColor: themeStyleData.bgColor }">
                    <span style="color: #fff;">提交订单</span>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'styleHome',
    components: {},
    props: {
        themeStyleData: {
            type: Object,
            default: { bgColor: '#EE0A24', textColor: '#FF9518' }
        },
        texTStyleData: {
            type: Object,
            default: { bgColor: '#EE0A24', textColor: '#FF9518' }
        }
    },
    data() {
        return {
        }
    },
    watch: {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.tc-box {
    color: #fff;
    font-size: 10px;
    padding: 5px;
    border-radius: 10px;
}

.flex {
    display: flex;
}

.box-item {
    border: 1px solid #f1f1f1;
    background-color: #f1f1f1;
}

.font-8 {
    font-size: 8px;
}
.qu-box {
    width: 15px;height: 15px;color: #fff;border-radius: 10px;text-align: center;
}
.jia-ru {
    font-size: 10px;
    padding: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #fff;
}

.gou-wu {
    background-color: #fff;
    font-size: 10px;
    padding: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.jia-ge {
    justify-content: space-between;
    margin: 10px 0;
    margin-top: 0;
    align-items: end;
}

.list-box {
    background-color: #fff;
    padding: 5px 5px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    
}
::v-deep .el-divider--horizontal{
    margin: 15px 0 0 0 !important;
}
.qr {
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #f1f1f1;
    justify-content: space-between;
    align-items: center;
}
.ri-btn {
    border-radius: 20px;
    padding: 5px 0;
    width: 90px;
    text-align: center;
    height: 30px;
}
</style>
