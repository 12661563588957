<template>
    <div>
        <div class="bg-Color marB10 x-f-end padd15">
            <el-button type="primary" size="mini" @click="configSave">保存</el-button>
        </div>
<!--        <cardTitleCom cardTitle="微信会员模板设置">-->
<!--            <template #cardContent>-->
<!--                <div class="padd15">-->
<!--                    <div class="y-start">-->
<!--                        <div class="x-f marB10">-->
<!--                            <el-button type="primary" size="small" @click="">引入模板</el-button>-->
<!--                            <el-button type="danger" size="small" @click="">删除</el-button>-->
<!--                        </div>-->
<!--                        <el-table-->
<!--                            border-->
<!--                            :data="tableData"-->
<!--                            style="width: 100%"-->
<!--                        >-->
<!--                            <el-table-column type="selection" width="55"></el-table-column>-->
<!--                            <el-table-column label="微信模板ID" prop="wxTemplateId" align="center" width="180"></el-table-column>-->
<!--                            <el-table-column label="消息标题" align="center" width="180"></el-table-column>-->
<!--                            <el-table-column label="微信消息标题" align="center" width="180"></el-table-column>-->
<!--                            <el-table-column label="使用模式" align="center" width="180"></el-table-column>-->
<!--                            <el-table-column label="参数列表" align="center" width="180"></el-table-column>-->
<!--                            <el-table-column label="结束语" align="center" width="180"></el-table-column>-->
<!--                            <el-table-column label="图文ID标题" align="center" width="180"></el-table-column>-->
<!--                            <el-table-column label="是否启用" align="center">-->
<!--                                <template v-slot="scope">-->
<!--                                    <el-switch-->
<!--                                        v-model="scope.row.isEnable"-->
<!--                                    >-->
<!--                                    </el-switch>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </template>-->
<!--        </cardTitleCom>-->
        <cardTitleCom cardTitle="商城浏览模式设置">
            <template #cardContent>
                <div class="padd15 y-start">
                    <span class="marB10">客户无需先登录，就能进入商城浏览商品信息，请勾选</span>
                    <el-checkbox v-model="form.mall_SYSTEM_CONFIG_MALL_BROWSE_21">客户未登录可先浏览商城</el-checkbox>
                </div>
            </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="商城商品单价及金额的取值方法">
            <template #cardContent>
                <div class="padd15">
                    <el-form :model="form">
                        <el-form-item label="单价舍入方式">
                            <el-select v-model="form.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_31" size="small" placeholder="请选择">
                                <el-option
                                    v-for="item in dict.type.round_off_type"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                    <div class="x-bc">
                                        <span style="">{{ item.label }}</span>
                                        <span style="color: #8492a6; font-size: 13px">{{ item.labelTOW }}</span>
                                    </div>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="金额舍入方式">
                            <el-select v-model="form.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_32" size="small" placeholder="请选择">
                                <el-option
                                    v-for="item in dict.type.round_off_type"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                    <div class="x-bc">
                                        <span style="">{{ item.label }}</span>
                                        <span style="color: #8492a6; font-size: 13px">{{ item.labelTOW }}</span>
                                    </div>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="收款舍入方式">
                            <el-select v-model="form.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_33" size="small" placeholder="请选择">
                                <el-option
                                    v-for="item in dict.type.round_off_type"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                    <div class="x-bc">
                                        <span style="">{{ item.label }}</span>
                                        <span style="color: #8492a6; font-size: 13px">{{ item.labelTOW }}</span>
                                    </div>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
            </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="库存模糊展示">
            <template #cardContent>
                <div class="padd15">
                    <el-table
                        border
                        :data="form.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41"
                    >
                        <el-table-column label="操作" align="center" width="180">
                            <template v-slot="scope">
                                <i
                                    @click="row('push', scope.$index)"
                                    class="el-icon-circle-plus operatePush"
                                ></i>
                                <i
                                    @click="row('del', scope.$index)"
                                    class="el-icon-remove operateDel"
                                ></i>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量（从）" align="center" width="180">
                            <template v-slot="scope">
                                <el-input type="number" v-model="scope.row.beginRange"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量（到）" align="center" width="180">
                            <template v-slot="scope">
                                <el-input type="number" v-model="scope.row.endRange"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="显示" align="center" width="180">
                            <template v-slot="scope">
                                <el-input type="number" v-model="scope.row.display"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否展示库存数量" align="center" width="180">
                            <template slot-scope="scope">
                                <el-switch class="marL10" v-model="scope.row.isDisplayStock"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label=""></el-table-column>
                    </el-table>
                </div>
            </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="库存展示设置">
            <template #cardContent>
                <div class="padd15">
                    <div class="marT10">
                        <div class="y-start marB10">
                            <span class="marB10  fontColor">若无需给下游订货客户展示商品具体库存，请勾选</span>
                            <el-radio v-model="form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_51" :label="true">商城不展示库存</el-radio>
                        </div>
                        <div class="y-start marB10">
                            <span class="marB10 fontColor">若需提供下游订货客户近一时期商品的大致可售卖库存量，请勾选</span>
                            <div class="x-f">
                                <el-radio v-model="form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52.switchConfig" :label="true">展示虚拟库存，虚拟库存扣减方式</el-radio>
                                <el-select placeholder="请选择" v-model="form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52.valueConfig">
                                    <el-option
                                        v-for="item in options1"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="y-start marB10">
                            <span class="marB10 fontColor">若需提供下游订货客户在进销存管理中的商品可用库存量，请勾选</span>
                            <div class="x-f">
                                <el-radio v-model="form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53.switchConfig" :label="true">展示基于进销存的商城可用库存</el-radio>
                                <el-select placeholder="请选择" v-model="form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53.valueConfig">
                                    <el-option
                                        v-for="item in options2"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="y-start marB10">
                            <span class="marB10 fontColor">计算方式:商城可用库存=进销存预计可用库存-商城未审核订单占用</span>
                            <el-checkbox v-model="form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_54">
                                商城订单下推成已审核的销售订单时，是否将商品库存锁库，以便精准管理库存
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </template>
        </cardTitleCom>
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom'
import CButton from '@/views/components/c-button/index.vue' //卡片标题
import {
    saveUpMallSystemConfig,
    getSystemMallConfigDetail
} from '@/api/O2OMall/config/sysConfig'

export default {
    name: 'shoppingSetting',
    dicts:['round_off_type'],
    components: {
        cardTitleCom,
        CButton
    },
    data() {
        return {
            form: {
                mall_SYSTEM_CONFIG_MALL_BROWSE_21: false,
                mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_31: '',
                mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_32: '',
                mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_33: '',
                mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41: [{}],
                mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_51: false,
                mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52: {
                    switchConfig: false,
                    valueConfig: ''
                },
                mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53: {
                    switchConfig: false,
                    valueConfig: ''
                },
                mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_54: false,
            },
            // tableData: [{}],
            options1:[
                {
                    label: '商城订单付款成功减商品库存',
                    value: '1'
                },
                {
                    label: '商城订单拍下减商品库存',
                    value: '2'
                },
            ],
            options2:[
                {
                    label: '库存不足无法下单',
                    value: '1'
                },
                {
                    label: '库存不足允许下单',
                    value: '2'
                },
            ],

        }
    },
    created() {
        this.getDetails()
    },
    methods: {
        getDetails(){
            getSystemMallConfigDetail().then(res => {
                this.form = {
                    mall_SYSTEM_CONFIG_MALL_BROWSE_21: res.data.mall_SYSTEM_CONFIG_MALL_BROWSE_21 != null ? res.data.mall_SYSTEM_CONFIG_MALL_BROWSE_21 : false,
                    mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_31: res.data.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_31,
                    mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_32: res.data.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_32,
                    mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_33: res.data.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_33,
                    mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41: res.data.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41 != null ? res.data.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41 : [{}],
                    mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_51: res.data.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_51 != null ? res.data.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_51 : false,
                    mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52: res.data.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52 != null ? res.data.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52 : {},
                    mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53: res.data.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53 != null ? res.data.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53 : {},
                    mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_54: res.data.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_54 != null ? res.data.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_54 : false,
                }


            })
        },
        configSave(){
            console.log('ID：',this.form)
            saveUpMallSystemConfig(this.form).then(res => {
                    console.log('保存成功', res.data)
                    // this.form.mallConfigBody = res.data.mallConfigBody
                    // this.form.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41 = res.data.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41
                    // this.form.wxTemplateConfigItems = res.data.wxTemplateConfigItems
                    this.$message.success('保存成功')
                })
            this.getDetails()
        },
        row(type, index) {
            if (type == 'push') {
                this.form.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41.splice(index + 1, 0, {})
            } else {
                if (this.form.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41.length <= 1) return
                this.form.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41.splice(index, 1)
            }
        }

    }
}
</script>

<style scoped>
.fontColor{
    color: #ababab;
}
</style>
