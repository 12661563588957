var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "bg-Color marB10 x-f-end padd15" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.freightSettingsSettings },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10",
        attrs: { cardTitle: "计费规则" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15 y-start fontColor" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", [_vm._v("按件数计算运费规则")]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item marL20",
                            attrs: {
                              effect: "dark",
                              content: _vm.content1,
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT10" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value:
                                _vm.form
                                  .mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_11,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_11",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_11",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("相同模板计算累加"),
                            ]),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("单个商品计算累加"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "marT20" }, [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", [_vm._v("按重量计算运费规则")]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item marL20",
                            attrs: {
                              effect: "dark",
                              content: _vm.content2,
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT10" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value:
                                _vm.form
                                  .mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_12,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_12",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_12",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("相同模板计算累加"),
                            ]),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("单个商品计算累加"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "marT20" }, [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", [_vm._v("按金额比例计算运费规则")]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item marL20",
                            attrs: {
                              effect: "dark",
                              content: _vm.content3,
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT10" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value:
                                _vm.form
                                  .mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_13,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_13",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_RULES_13",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("相同模板计算累加"),
                            ]),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("单个商品计算累加"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10",
        attrs: { cardTitle: "商城运费" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15 fontColor" }, [
                  _c("div", [
                    _c("span", [_vm._v("请选择运费对应的客户承担费用")]),
                    _c(
                      "div",
                      { staticClass: "x-f marT20" },
                      [
                        _c("span", { staticStyle: { width: "80px" } }, [
                          _vm._v("费用类型"),
                        ]),
                        _c("select-frame", {
                          attrs: {
                            tableData: _vm.tableData,
                            suffixIcon: "el-icon-arrow-down",
                          },
                          on: { selectRowObj: _vm.selectRowObj },
                          model: {
                            value:
                              _vm.form
                                .mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_21,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_21",
                                $$v
                              )
                            },
                            expression:
                              "form.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_21",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "marT20" },
                    [
                      _c("span", [_vm._v("整单包邮设置")]),
                      _c(
                        "el-table",
                        {
                          ref: "tableData1",
                          staticClass: "marT10",
                          attrs: {
                            border: "",
                            width: "100%",
                            data: _vm.mall_SYSTEM_CONFIG_FREIGHT_SETTINGS_MALL_FREIGHT_22,
                          },
                          on: {
                            "cell-mouse-enter": _vm.cellMouseEnter,
                            "cell-mouse-leave": _vm.cellMouseLeave,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { width: "80", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.hoverRow
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus operatePush",
                                          on: {
                                            click: function ($event) {
                                              return _vm.row(
                                                "push",
                                                scope.$index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    scope.row.hoverRow
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-remove operateDel",
                                          on: {
                                            click: function ($event) {
                                              return _vm.row(
                                                "del",
                                                scope.$index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    !scope.row.hoverRow
                                      ? _c("div", [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "区域",
                              align: "center",
                              width: "260",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "omit",
                                        staticStyle: { width: "120px" },
                                      },
                                      [_vm._v(_vm._s(scope.row.region))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "满包邮金额",
                              prop: "amount",
                              align: "center",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        min: _vm.min,
                                        size: "mini",
                                        onkeypress:
                                          "return (/^(([1-9]{1}\\d*)|(0{1}))(\\.\\d{1,2})?$/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8",
                                        type: "number",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.aaaa($event)
                                        },
                                      },
                                      model: {
                                        value: scope.row.amount,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "amount", $$v)
                                        },
                                        expression: "scope.row.amount",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "设置区域",
                              align: "center",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: !_vm.isClick,
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openDialog(scope.$index)
                                          },
                                        },
                                      },
                                      [_vm._v("设置 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.regionVisible,
            width: "700px",
            "before-close": _vm.close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.regionVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "dialogTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.regionData,
                    height: "300",
                    border: "",
                    "tooltip-effect": "dark",
                    "row-key": _vm.getRowKeys,
                  },
                  on: { "selection-change": _vm.onContactSelectChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      align: "center",
                      label: "编码",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "label", align: "center", label: "名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "行政级次", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v("省")]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.dialogOk } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }