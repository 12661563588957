<template>
    <div>
        <div class="bg-Color marB10 x-f-end padd15">
            <el-button type="primary" size="mini" @click="setafterSalesSettings">保存</el-button>
        </div>
        <cardTitleCom cardTitle="到货设置" class="bg-Color marT10">
            <template #cardContent>
                <div class="padd15 x-f fontColor">
                    <span>请选择发货状态后，自动确认到货的时间，请填写</span>
                    <el-input class="marL10" v-model="form.mall_SYSTEM_CONFIG_AFTER_SALES_SETTINGS_11" type="number" style="width: 180px"></el-input>
                    <span class="marL10">天</span>
                </div>
            </template>
        </cardTitleCom>
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
    saveUpMallAfterSalesSettingsConfig,
    getMallAfterSalesSettingsConfigDetail
} from '@/api/O2OMall/config/sysConfig'
export default {
    name: 'afterSaleSetting',
    components:{
        cardTitleCom
    },
    data(){
        return{
            receivingTime: 0,
            form:{
                mall_SYSTEM_CONFIG_AFTER_SALES_SETTINGS_11: ''
            }
        }
    },
    created() {
        this.getDetails()
    },
    methods:{
        getDetails(){
            getMallAfterSalesSettingsConfigDetail().then(res => {
                console.log('售后：',res)
                this.form = res.data
                // this.receivingTime = receivingTime
            })
        },
        setafterSalesSettings(){
            saveUpMallAfterSalesSettingsConfig(this.form).then(res => {
                // console.log('售后修改成功', res)
                this.$message.success(res.msg)
                this.getDetails()
            })
        }

    }
}
</script>

<style scoped>

</style>
