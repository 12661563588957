<template>
    <div class="container">
        <cardTitleCom cardTitle="基础信息" class="bg-Color">
            <template #cardContent>
                <div class="padd15 x-f fontColor">
                   <el-form :model="form"  label-width="130px">
                       <el-form-item label="商城名称：">
                           <el-input size="mini" v-model="form.mallName" class="inputW260"></el-input>
                       </el-form-item>
                       <el-form-item label="商城Logo：">
                           <AccessoryUpload listType="picture-card" size="mini" title="" :limit="1" :fileList="fileList"
                                @getFileItems="getFileItems" @delFileItems="deleteImg" :noneBtnImg="form.mallLogoUrl != ''"></AccessoryUpload>
                       </el-form-item>
                       <el-form-item label="客服电话：">
                           <el-input size="mini" v-model="form.serviceTelephone" class="inputW260"></el-input>
                       </el-form-item>
                       <el-form-item>
                           <el-button type="primary" size="mini" @click="setSave">保 存</el-button>
                       </el-form-item>
                       <el-divider v-if="form.mobileLink != null"></el-divider>
                       <el-form-item label="移动端链接：" v-if="form.mobileLink != null">
                           <div class="x-f">
                               <span id="mobileLink">{{form.mobileLink}}</span>
                               <el-button size="mini" class="marL10" type="text" @click="copyUrl">复制链接</el-button>
                           </div>
                       </el-form-item>
                       <el-form-item label="商城二维码：" v-if="form.previewQrCodeUr != null">
                          <el-image class="img pointer" :preview-src-list="srcList" @click="vbs" :src="form.previewQrCodeUr"/>
                       </el-form-item>
                   </el-form>
                </div>
            </template>
        </cardTitleCom>
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import AccessoryUpload from '@/views/components/accessoryUpload/index.vue'
import {
    previewDetail,
    previewSave,
    previewUpdate
} from '@/api/O2OMall/config/preview'
export default {
    name: 'index',
    components:{
        AccessoryUpload,
        cardTitleCom
    },
    data(){
        return{
            form:{
                mallPreviewId: null,
                mallLogoUrl: '',
                mallName: '',
                mobileLink: '',
                previewQrCodeUr: '',
                serviceTelephone: ''
            },
            fileList: [],
            srcList: [],
        }
    },
    created() {
        this.getDetails()
    },
    methods:{
        getDetails(){
            previewDetail().then(res => {
                console.log('aaaa:', res)
                this.fileList = []
                this.form = res.data
                if(res.data && res.data.mallLogoUrl != null){
                    this.fileList.push(
                        {
                            url: res.data.mallLogoUrl
                        }
                    )
                    this.form.mallLogoUrl = res.data.mallLogoUrl
                }

            })
        },
        setSave(){
            console.log('form:', this.form.mallPreviewId)
            if(this.form.mallPreviewId == null){
                previewSave(this.form).then(res =>{
                    this.$message.success(res.msg)
                })
            } else {
                previewUpdate(this.form).then(res => {
                    this.$message.success(res.msg)
                })
            }
            this.getDetails()
        },
        // 复制
        copyUrl(){
            // const inputElement = document.querySelector('#mobileLink');
            var textarea = document.createElement('textarea');
            document.body.appendChild(textarea);
            // 隐藏此输入框
            textarea.style.position = 'fixed';
            textarea.style.clip = 'rect(0 0 0 0)';
            textarea.style.top = '10px';
            // 赋值
            textarea.value = this.form.mobileLink;
            // 选中
            textarea.select();
            // 复制
            document.execCommand('copy', true);
            // 移除输入框
            document.body.removeChild(textarea);
            this.$message.success('复制成功')
        },
        getFileItems(file){
            console.log('上传图片：', file)
            this.form.mallLogoUrl = file.accessUrl
        },
        // 删除LOGO
        deleteImg(){
            this.form.mallLogoUrl = ''
        },
        // 二维码预览
        vbs(){
            this.srcList = []
            this.srcList.push(this.form.previewQrCodeUr)
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 10px;
    background: #e9e9e9;
    font-size: 13px;

    .fontColor {
        color: #949494;
    }
    .inputW260{
        width: 260px;
    }
    .img{
        width: 210px;
        height: 210px;
    }
}
</style>
