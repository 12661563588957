<template>
    <div class="">
        <div class="x-f">
            <el-select class="inputW100" v-model="day" size="small" placeholder="请选择" @change="selectChange">
                <el-option
                    v-for="item in dayList"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
            </el-select>
            <span class="name">日</span>
            <el-select class="inputW100" v-model="hour" size="small" placeholder="请选择" @change="selectChange">
                <el-option
                    v-for="item in hourList"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
            </el-select>
            <span class="name">时</span>
            <el-select class="inputW100" v-model="minute" size="small" placeholder="请选择" @change="selectChange">
                <el-option
                    v-for="item in minuteList"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
            </el-select>
            <span class="name">分</span>
        </div>
        <div style="color: #999999">
            <!-- 若设置1日，用户开团后，需要在1日内成团，超时则拼团失败 -->
            
            {{ tips }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'selectDayTime',
    props: {
        expirationDate: {
            type: String,
            default: ''
        },
        tips: {
            type: String,
            default: '若设置1日，用户开团后，需要在1日内成团，超时则拼团失败'
        },
    },
    data() {
        return {
            day: 1,
            hour: 1,
            minute: 0,

            dayList: [],
            hourList: [],
            minuteList: []
        }
    },
    watch:{
        expirationDate(nVal) {
            if(nVal){
                let arr = nVal.split(':')
                this.day = arr[0]
                this.hour = arr[1]
                this.minute = arr[2]
                console.log('arr==', arr)
            } else{
                this.day = 0
                this.hour = 3
                this.minute = 0
            }
            // console.log('------------------expirationDate:', this.day, this.hour, this.minute)
        }
    },
    created() {
        for (let i = 0; i < 31; i++) {
            this.dayList.push(i)
        }
        for (let i = 0; i < 25; i++) {
            this.hourList.push(i)
        }
        for (let i = 0; i < 61; i++) {
            this.minuteList.push(i)
        }

    },
    methods: {
        selectChange() {
            console.log('------天------', this.day)
            if (this.day != 0 || this.hour != 0 || this.minute != 0) {
                this.$emit('selectDay', this.day + ':' + this.hour + ':' + this.minute)
            }

        }
    }
}
</script>

<style scoped>
.inputW100 {
    width: 70px;
}

.name {
    margin: 0 10px;
}
</style>
