var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "bg-Color marB10 x-f-end padd15" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.setTransactionSettings },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10",
        attrs: { cardTitle: "超时设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15 y-start fontColor" }, [
                  _c("div", { staticClass: "marB20" }, [
                    _c("span", { staticClass: "fontColor" }, [
                      _vm._v("先款后货订单是否开启超时自动取消订单"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value:
                                _vm.form
                                  .mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11
                                  .switchConfig,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form
                                    .mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11,
                                  "switchConfig",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11.switchConfig",
                            },
                          },
                          [_vm._v("开启超时自动取消")]
                        ),
                        _c("span", [
                          _vm._v(
                            "（下单后，系统每半小时检查一次系统待审核订单，若已超过"
                          ),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "marL10 marR10",
                            staticStyle: { width: "100px" },
                            attrs: { size: "small", placeholder: "请选择" },
                            model: {
                              value:
                                _vm.form
                                  .mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11
                                  .valueConfig,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form
                                    .mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11,
                                  "valueConfig",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11.valueConfig",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: {
                                label: item.label + "分钟",
                                value: item.value,
                              },
                            })
                          }),
                          1
                        ),
                        _c("span", [
                          _vm._v("仍然未完成支付，将自动取消订单）"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("span", [_vm._v("支持商城订单0元支付")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        model: {
                          value:
                            _vm.form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_12,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_12",
                              $$v
                            )
                          },
                          expression:
                            "form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_12",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }