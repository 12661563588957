<template>
  <div>
    <!-- <div style="position: relative;height: 100px;background: #e9e9e9;">
                <div style="position: absolute;right: 30px;top: 40px;">
                    <el-button type="primary" @click="save">保存</el-button>
                </div>
            </div> -->
    <topOperatingButton :showAudit="false" :isSubmitAddBtn="false" :isAuditBillBtn="false" :isQuitBtn="false"
      @submitForm="save(false)" id="topOperatingButton">
    </topOperatingButton>
    <div class="container x-start">

      <div class="leftBox padd15">
        <div class="item x-c cursorP" :class="{ 'clickStyle': leftItem == index }" v-for="(item, index) in leftBtnList"
          :key="index" @click="itemClick(index)">
          {{ item.name }}
        </div>
      </div>
      <div class="rightBox marL10" style="overflow-y: scroll">
        <div class="flex" style="padding: 40px 0 40px 20px;">
          <!-- <div style="position: absolute;right: 30px;">
                        <el-button type="primary" @click="save">保存</el-button>
                    </div> -->
        </div>
        <div class="flex right-btm">
          <div class="" style="padding-left: 100px;">
            <div class="flex">
              <span>全店风格</span>
            </div>
            <div class="btm-color" style="margin-bottom: 40px;">
              <div>
                <!-- 主题色 -->
                <span>
                  <span v-for="(item, index) in themeColor" :key="index" @click="checkBg(1, index)">
                    <span class="flex cube1" style="display: inline-block;" v-if="!item.active"
                      :style="{ border: themeIsActive === index ? `1px solid rgb(64, 158, 255)` : '' }">
                      <!-- <span class="flex">风格{{ index + 1 }}<span v-if="themeIsActive === index" style="color: cornflowerblue;">(使用中)</span></span> -->
                      <span class="flex">
                        <span :style="{ width: '20px', height: '20px', backgroundColor: item.bgColor }">
                        </span>
                        <span :style="{ width: '20px', height: '20px', backgroundColor: item.textColor }">
                        </span>
                      </span>
                    </span>
                    <span v-else>
                      <span style="width: 130px;" class="flex cube1">
                        <span>自定义</span>
                        <!-- <span class="flex">
                                                    <el-color-picker v-model="item.bgColor" size="mini"></el-color-picker>
                                                    <el-color-picker v-model="item.textColor" size="mini"></el-color-picker>
                                                </span> -->
                      </span>
                      <span style="width: 130px;" class="flex cube1"
                        :style="{ border: themeIsActive === index ? `1px solid rgb(64, 158, 255)` : '' }">
                        <!-- <span>自定义</span> -->
                        <span class="flex">
                          <!-- <div :style="{ width: '20px', height: '20px', backgroundColor: item.bgColor }">
                                                    </div> -->
                          <el-color-picker v-model="item.bgColor" size="mini"></el-color-picker>
                          <el-color-picker v-model="item.textColor" size="mini"></el-color-picker>
                          <!-- <div
                                                        :style="{ width: '20px', height: '20px', backgroundColor: item.textColor, marginLeft: '10px' }">
                                                    </div> -->
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <!-- 预览 -->
              <div></div>
            </div>
            <div class="flex">
              <span>价格用色</span>
            </div>
            <div style="background: #ffffff;">
              <div>
                <!-- 主题色 -->
                <span>
                  <span v-for="(item, index) in textColor" :key="index" @click="checkBg(2,index)">
                    <span class="flex cube1" style="display: inline-block;" v-if="!item.active"
                      :style="{ border: textIsActive === index ? `1px solid rgb(64, 158, 255)` : '' }">
                      <!-- <div class="flex">风格{{ index + 1 }}<div v-if="textIsActive === index" style="color: cornflowerblue;">(使用中)</div></div> -->
                      <span class="flex">
                        <span :style="{ width: '20px', height: '20px', backgroundColor: item.bgColor }">
                        </span>
                      </span>
                    </span>
                    <span v-else>
                      <span style="width: 130px;" class="flex cube1">
                        <span>自定义</span>
                        <!-- <span class="flex">
                                                    <el-color-picker v-model="item.bgColor" size="mini"></el-color-picker>
                                                </span> -->
                      </span>
                      <span style="width: 130px;" class="flex cube1"
                        :style="{ border: textIsActive === index ? `1px solid rgb(64, 158, 255)` : '' }">
                        <!-- <span>自定义</span> -->
                        <span class="flex">
                          <el-color-picker v-model="item.bgColor" size="mini"></el-color-picker>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <!-- <div>
                                    <div v-for="(item, index) in textColor" :key="index" @click="checkBg(2,index)">
                                        <div class="flex cube" v-if="!item.active"
                                            :style="{ border: textIsActive === index ? `1px solid #4a4dd8` : '' }">
                                            <div class="flex">风格{{ index + 1 }}<div v-if="textIsActive === index" style="color: cornflowerblue;">(使用中)</div></div>
                                            <div class="flex">
                                                <div :style="{ width: '20px', height: '20px', backgroundColor: item.bgColor }">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex cube" v-else
                                            :style="{ border: textIsActive === index ? `1px solid #4a4dd8` : '' }">
                                            <div>自定义</div>
                                            <div class="flex">
                                                <el-color-picker v-model="item.bgColor" size="mini"></el-color-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
              </div>
              <!-- 预览 -->
              <div></div>
            </div>
          </div>
          <div class="flex">
            <div style="padding: 20px;">
              <StyleHmoe :themeStyleData="themeStyleData" :texTStyleData="texTStyleData"></StyleHmoe>
            </div>
            <div style="padding: 20px;">
              <StyleDetails :themeStyleData="themeStyleData" :texTStyleData="texTStyleData"></StyleDetails>
            </div>
            <div style="padding: 20px;">
              <StyleOrders :themeStyleData="themeStyleData" :texTStyleData="texTStyleData"></StyleOrders>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import StyleHmoe from '@/views/O2OMall/config/style/components/home.vue'
import StyleDetails from '@/views/O2OMall/config/style/components/details.vue'
import StyleOrders from '@/views/O2OMall/config/style/components/orders.vue'
import {
  gettHemeColor,
  configStyleSave,
  updateIsUse,
  upHemeColor
} from "@/views/O2OMall/config/style/api/index";

export default {
  name: 'index',
  components: { StyleHmoe, StyleDetails, StyleOrders, topOperatingButton },
  data() {
    return {
      leftBtnList: [
        { id: 1, name: '风格主题' },
      ],
      leftItem: 0, // 左边菜单点击项
      themeColor: [
        // { bgColor: '#2F2F34', textColor: '#EBECF2' },
        // { bgColor: '#C3A769', textColor: '#F3EEE1', active: true },
      ],
      textColor: [
        // { bgColor: '#2F2F34', textColor: '#EBECF2' },
        // { bgColor: '#C3A769', textColor: '#F3EEE1', active: true },
      ],
      themeStyleData: {
        bgColor: '#fd5b34', textColor: '#ffeeec'
      },
      texTStyleData: {
        bgColor: '#fd5b34', textColor: '#ffeeec'
      },
      themeIsActive: 0,
      textIsActive: 0,
    }
  },
  watch: {
  },
  async created() {
    const { data } = await gettHemeColor()
    if (data) {
      const themeColor = data.filter((x) => x.configType == 1)?.map((y, index) => {
        if (y.isUse) {
          this.themeIsActive = index
        }
        return {
          ...y, bgColor: y.styleColor1, textColor: y.styleColor2, active: y.styleType != 100 ? false : true
        }
      });
      const textColor = data.filter((x) => x.configType == 2)?.map((y,index) => { 
        if (y.isUse) {
          this.textIsActive=index
        }
        return { ...y, bgColor: y.styleColor1, textColor: y.styleColor2, active: y.styleType != 100 ? false : true } });
      this.themeColor = [...themeColor, ...this.themeColor]
      this.textColor = [...textColor, ...this.textColor]
      if ( this.themeIsActive==0) {
      this.themeStyleData = this.themeColor[0]
      }else{
        this.checkBg(1, this.themeIsActive)
      }
      if ( this.textIsActive==0) {
      this.texTStyleData = this.textColor[0]
      }else{
        this.checkBg(2, this.textIsActive)
      }
    }
  },
  methods: {
    itemClick(index) {
      console.log('---', index)
      this.leftItem = index
    },
    checkBg(type, index) {
      if (type == 1) {
        this.themeIsActive = index
        this.themeStyleData = this.themeColor[index]
      } else {
        this.textIsActive = index
        this.texTStyleData = this.textColor[index]
      }
    },
    // saveColor() {
    //     // configStyleSave({
    //     //     configType: 2,
    //     //     styleType: 2,
    //     //     isUse: false,
    //     //     styleColor1: '#4767BA',
    //     //     styleColor2: '#83A4F7',
    //     //     sortNo: 2
    //     // }).then((res) => {
    //     //     this.$message.success("新增模版成功");
    //     // })
    // },
    save() {
      let parasm = [
        {
          configType: this.themeColor[this.themeIsActive].configType,
          styleColor1: this.themeColor[this.themeIsActive].bgColor,
          styleColor2: this.themeColor[this.themeIsActive].textColor,
          styleType: this.themeColor[this.themeIsActive].styleType,
          isUse: true,
        },
        {
          configType: this.textColor[this.textIsActive].configType,
          styleColor1: this.textColor[this.textIsActive].bgColor,
          styleColor2: this.textColor[this.textIsActive].textColor,
          styleType: this.textColor[this.textIsActive].styleType,
          isUse: true,
        }
      ]
      updateIsUse(parasm).then((res) => {
        this.$message.success("保存成功");
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;
  font-size: 13px;

  .leftBox {
    width: 210px;
    height: calc(100vh - 100px);
    background: #ffffff;
    padding-top: 50px;
    .item {
      height: 50px;
      font-size: 16px;
      font-weight: 550;
    }
  }

  .rightBox {
    width: calc(100% - 220px);
    // height: calc(100vh - 100px);
    background: #ffffff;
  }

  .fontColor {
    color: #949494;
  }
}

.clickStyle {
  background: #e8f1fc;
}

.right-btm {
  //     width: 100%;
  //     height: calc(100vh - 160px);
  background: #ffffff;
  //     overflow-y: auto;
  padding: 10px;
}

.flex {
  display: flex;
}

.cube1 {
  // width: 50px;
  margin-right: 10px;
  justify-content: space-between;
  align-items: center;
  // background-color: #f1f1f1;
  padding: 2px;
  margin-top: 10px;
  border: 1px solid #ffffff;
}
.cube {
  width: 200px;
  height: 35px;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
  margin-top: 10px;
}

.btm-color {
  width: 500px;
  padding-right: 100px;
  // height: calc(100vh - 300px);
  background: #ffffff;
  // overflow-y: auto;
}
</style>
