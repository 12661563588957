<template>
    <div class="container x-start">
        <div class="leftBox padd15">
            <div class="item x-c cursorP" :class="{'clickStyle': leftItem == index}" v-for="(item, index) in leftBtnList" :key="index" @click="itemClick(index)">
                {{ item }}
            </div>
        </div>
        <div class="rightBox marL10" style="overflow-y: scroll">
            <shopping-setting v-if="leftItem == 0"/>
            <deal-setting v-if="leftItem == 1"/>
            <after-sale-setting v-if="leftItem == 2"/>
            <freight-configuration v-if="leftItem == 3"/>
            <liveSetting v-if="leftItem == 4"/>
            <shoppengMessage v-if="leftItem == 5"/>
        </div>
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import ShoppingSetting from '@/views/O2OMall/config/sysConfig/components/shoppingSetting.vue'
import ShoppengMessage from '@/views/O2OMall/config/sysConfig/components/shoppengMessage.vue'
import AfterSaleSetting from '@/views/O2OMall/config/sysConfig/components/afterSaleSetting.vue'
import FreightConfiguration from '@/views/O2OMall/config/sysConfig/components/freightConfiguration.vue'
import LiveSetting from '@/views/O2OMall/config/sysConfig/components/liveSetting.vue'
import DealSetting from '@/views/O2OMall/config/sysConfig/components/dealSetting.vue'
export default {
    name: 'index',
    components: {
        cardTitleCom,
        ShoppingSetting,
        ShoppengMessage,
        AfterSaleSetting,
        FreightConfiguration,
        LiveSetting,
        DealSetting
    },
    data() {
        return {
            leftBtnList: [
                '商城设置',
                '交易设置',
                '售后设置',
                '运费配置',
                '直播设置',
                '商城信息',
            ],
            leftItem: 0, // 左边菜单点击项


            value: false,
            radio: '',
            tableData: [{}],
            isRadio: '2',
            isradio: '',
            checkList: [],
            form:{
                selectValue: '',
            },
            cities: [
                {
                    label: '不舍入',
                    labelTOW: '1.55-1.55',
                    value: '1'
                },
                {
                    label: '舍去分',
                    labelTOW: '1.55-1.50',
                    value: '2'
                },
                {
                    label: '舍去角',
                    labelTOW: '1.55-1.00',
                    value: '3'
                },
                {
                    label: '四舍五入到分',
                    labelTOW: '1.55-1.55',
                    value: '4'
                },
                {
                    label: '四舍五入到角',
                    labelTOW: '1.55-1.60',
                    value: '5'
                },
                {
                    label: '四舍五入到元',
                    labelTOW: '1.55-2.00',
                    value: '6'
                }

            ],
        }
    },
    watch: {
        isRadio(nV) {
            if (nV == 2) {
                this.isradio = '6'
            }
        }
    },
    methods: {

        itemClick(index){
            console.log('---', index)
            this.leftItem = index
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 10px;
    background: #e9e9e9;
    font-size: 13px;
    .leftBox{
        width: 210px;
        height: calc(100vh - 100px);
        background: #FFFFFF;
        .item{
            height: 50px;
            font-size: 16px;
            font-weight: 550;
        }
    }
    .rightBox{
        width: calc(100% - 220px);
        height: calc(100vh - 100px);
    }
    .fontColor {
        color: #949494;
    }
}

.clickStyle{
    background: #e8f1fc;
}
</style>
