<template>
    <div class="box-item" style="position: relative;cursor: pointer;">
        <div class="" style="position: relative;">
            <div style="padding: 5px 5px;background-color: #fff;">
                <el-image style="width: 200px;height: 150px;"
                    src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png"></el-image>
            </div>
            <div style="padding: 5px 5px;background-color: #fff;">
                <div class="flex jia-ge">
                    <div :style="{color: texTStyleData.bgColor }">
                        <span>￥56</span>
                        <span style="font-size: 8px;">/6寸</span>
                    </div>
                    <div style="font-size: 8px;">
                        <span>库存94</span>
                        <span>销量8</span>
                    </div>
                </div>
                <div style="margin: 10px 0;font-size: 12px;color: red;font-size: 8px;text-align: right;">
                    <span>领卷></span>
                </div>
                <div class="flex" style="justify-content: space-between;margin: 10px 0;align-items: end;">
                    <span style="font-weight: 700;">提拉米苏</span>
                    <span style="font-size: 8px;">分享</span>
                </div>
            </div>
            <div style="margin: 10px 0;padding: 5px 5px;background-color: #fff;border-radius: 8px;">
                <div class="flex" style="justify-content: space-between;align-items: end;">
                    <span style="color: #ccc;">选择</span>
                    <span style="font-size: 12px;">请选择商品规格></span>
                </div>
            </div>
            <div style="margin: 10px 0;padding: 5px 5px;background-color: #fff;">
                <div class="flex" style="justify-content: space-between;align-items: end;">
                    <span style="font-weight: 700;font-size: 14px;">商品详情</span>
                </div>
                <div>
                    <div style="background-color: #fff;">
                        <el-image style="width: 200px;height: 150px;"
                            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png"></el-image>
                    </div>
                </div>
            </div>
            <div style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: #ccc;opacity: 0.5;">
            </div>
        </div>
        <div class="tc-box"
            style="padding: 5px 5px;position: absolute;bottom: 0;left: 0;width: 100%;height: 300px;background-color: #fff;">
            <div style="text-align: right;">
                <i class="el-icon-close"></i>
            </div>
            <div>
                <div class="flex">
                    <el-image style="width: 70px;height: 70px;"
                        src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-10/359d5ec9c82f4aa49addf4e38241191e.png"></el-image>
                    <div class="flex" style="flex-flow: column;justify-content: space-between;margin-left: 10px;flex: 1;">
                        <span style="font-weight: 700;">提拉米苏</span>
                        <span style="font-size: 12px;">库存: 94</span>
                        <div class="flex" style="justify-content: space-between;">
                            <span :style="{color: texTStyleData.bgColor}">￥56</span>
                            <div class="flex" style="align-items: center;">
                                <div class="yq-box">-</div>
                                <div style="font-size: 10px;margin: 0 5px">1</div>
                                <div class="yq-box" :style="{ backgroundColor: themeStyleData.bgColor, color: '#fff' }">+</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style="font-weight: 700;margin: 7px 0;">规格</div>
                    <div class="fk-box" :style="{ backgroundColor: `${themeStyleData.bgColor}30`, color: themeStyleData.bgColor, width: '31px'}">6寸</div>
                    <div style="font-weight: 700;margin: 7px 0;">甜度</div>
                    <div class="flex" style="">
                        <div class="fk-box">少糖</div>
                        <div class="fk-box"
                            style="padding: 5px;background-color: #f1f1f1;border-radius: 3px;margin: 0 5px;">标准糖</div>
                        <div class="fk-box">多糖</div>
                    </div>
                </div>
            </div>
            <div class="flex qr" :style="{ backgroundColor: themeStyleData.bgColor }">
                <div :style="{}">确认</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'styleHome',
    components: {},
    props: {
        themeStyleData: {
            type: Object,
            default: { bgColor: '#EE0A24', textColor: '#FF9518' }
        },
        texTStyleData: {
            type: Object,
            default: { bgColor: '#EE0A24', textColor: '#FF9518' }
        },
    },
    data() {
        return {
        }
    },
    watch: {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
}

.box-item {
    border: 1px solid #f1f1f1;
    background-color: #f1f1f1;
}

.font-8 {
    font-size: 8px;
}

.jia-ru {
    font-size: 10px;
    padding: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #fff;
}

.gou-wu {
    color: #fff;
    font-size: 10px;
    padding: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.jia-ge {
    justify-content: space-between;
    margin: 10px 0;
    margin-top: 0;
    align-items: end;
}

.qr {
    color: #ffffff;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 30px;
    margin-top: 62px;
}

.tc-box {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.yq-box {
    width: 18px;
    height: 18px;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    text-align: center;
    color: #ccc;
}

.fk-box {
    padding: 5px;
    background-color: #f1f1f1;
    border-radius: 3px;
    font-size: 12px;
}</style>
