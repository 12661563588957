//商品详情组件
export const goodsDetail = [
  {
    name: 'shangpintu',
    icon: 'shangpinlunbo',
    text: '商品图',
    cursor: false,
    maximum: 1,
    num: 0,
    place: ['goodsDetail']
  },
  {
    name: 'shangpinxinxi',
    icon: 'shangpinxinxi',
    text: '商品信息',
    cursor: false,
    maximum: 1,
    num: 0,
    place: ['goodsDetail']
  },
  {
    name: 'shangpinguige',
    icon: 'shangpinguige',
    text: '商品规格',
    cursor: false,
    maximum: 1,
    num: 0,
    place: ['goodsDetail']
  },
  {
    name: 'shangpinpingjia',
    icon: 'comment',
    text: '商品评价',
    cursor: false,
    maximum: 1,
    num: 0,
    place: ['goodsDetail']
  },
  {
    name: 'shangpinxiangqing',
    icon: 'doc-detail',
    text: '商品详情',
    cursor: false,
    maximum: 1,
    num: 0,
    place: ['goodsDetail']
  },
  {
    name: 'dibudaohang',
    icon: 'dibudaohang',
    text: '底部导航',
    cursor: false,
    maximum: 1,
    num: 0,
    place: ['goodsDetail']
  }
]