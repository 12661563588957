var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "bg-Color marB10 x-f-end padd15" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.getliveBroadcastSettings },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        staticClass: "bg-Color marT10",
        attrs: { cardTitle: "小程序直播设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15 fontColor" }, [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("span", [_vm._v("开启小程序直播服务")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        model: {
                          value:
                            _vm.form
                              .mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_11,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_11",
                              $$v
                            )
                          },
                          expression:
                            "form.mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_11",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "x-f marT10" }, [
                    _vm._v(
                      " 注: 仅支持小程序订货商城可开启小程序直播入口，开启服务前需在微信小程序后台开通直播功能，操作指引清参考: "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "marL10",
                        staticStyle: { color: "#1c75f4" },
                        attrs: { href: "##" },
                      },
                      [_vm._v("小程序商城直播开启指引")]
                    ),
                  ]),
                  _c("div", { staticClass: "x-f marT10" }, [
                    _c("span", [_vm._v("直播间选择模式")]),
                    _c(
                      "div",
                      { staticClass: "marL20" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value:
                                _vm.form
                                  .mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12",
                            },
                          },
                          [_vm._v("自动选取待直播或正在直播的房间")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2" },
                            model: {
                              value:
                                _vm.form
                                  .mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_LIVE_BROADCAST_SETTINGS_12",
                            },
                          },
                          [_vm._v("手动选择直播房间")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }