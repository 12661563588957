<template>
  <div class="container">
    <cardTitleCom cardTitle="基础信息" class="bg-Color marT10">
      <template #cardContent>
        <div class="padd15">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="商城支付参数" name="weixin">
              <div style="width: 800px" class="x-c">
                <el-form :model="weChatForm" ref="ruleForm" label-width="200px">
                  <el-form-item label="是否启用">
                    <el-switch v-model="weChatForm.isStop"></el-switch>
                  </el-form-item>
                  <el-form-item label="支付通道方案:">
                    <el-select
                      v-model="weChatCannel"
                      class="inputW260"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in channelList"
                        :key="item.payChannelId"
                        :label="item.payChannelName"
                        :value="item.payChannelId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item label="接入类型">
                    <el-select
                      v-model="weChatCannel"
                      class="inputW260"
                      placeholder="请选择"
                      @change="weChatCannelChange"
                    >
                      <el-option
                        v-for="item in channelList"
                        :key="item.payChannelId"
                        :label="item.payChannelName"
                        :value="item.payChannelId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item> -->
                  <div v-show="weChatCannel">
                    <el-form-item label="接入类型">
                      <el-input
                        class="inputW260"
                        v-model="weChatForm.payChannelTypeName"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="第三方支付AppID"
                      v-show="
                        weChatForm.payChannelType == 1 || weChatForm.payChannelType == 2
                      "
                      prop="merchantAppId"
                    >
                      <el-input
                        v-model="weChatForm.merchantAppId"
                        placeholder="请输入第三方支付AppID"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="第三方支付密钥(Secret)"
                      v-show="
                        weChatForm.payChannelType == 1 || weChatForm.payChannelType == 2
                      "
                      prop="merchantKey"
                    >
                      <el-input
                        v-model="weChatForm.merchantKey"
                        placeholder="请输入第三方支付密钥(Secret)"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="第三方支付商户号"
                      v-show="
                        weChatForm.payChannelType == 1 || weChatForm.payChannelType == 2
                      "
                      prop="merchantNo"
                    >
                      <el-input
                        v-model="weChatForm.merchantNo"
                        placeholder="请输入第三方支付商户号"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="第三方支付子商户AppID"
                      v-show="weChatForm.payChannelType == 2"
                      prop="merchantSubAppId"
                    >
                      <el-input
                        v-model="weChatForm.merchantSubAppId"
                        placeholder="请输入第三方支付子商户AppID"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="第三方支付子商户号"
                      v-show="weChatForm.payChannelType == 2"
                      prop="merchantSubNo"
                    >
                      <el-input
                        v-model="weChatForm.merchantSubNo"
                        placeholder="请输入第三方支付子商户号"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </div>
                  <el-form-item>
                    <el-button type="primary" size="mini" @click="WeChatSubmit"
                      >保存</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="支付宝支付参数" name="alipay">
                            <div style="width: 800px" class="x-c">
                                <el-form :model="alipay" ref="aliForm" label-width="200px">
                                    <el-form-item label="是否启用">
                                        <el-switch v-model="alipay.isStop"></el-switch>
                                    </el-form-item>
                                    <el-form-item label="支付通道:">
                                        <el-select v-model="alipayCannel" class="inputW260" placeholder="请选择"
                                                   @change="alipayCannelChange"
                                        >
                                            <el-option
                                                v-for="item in channelList"
                                                :key="item.payChannelId"
                                                :label="item.payChannelName"
                                                :value="item.payChannelId"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <div v-show="alipayCannel">
                                        <el-form-item label="支付宝账号：">
                                            <el-input class="inputW260" v-model="alipay.merchantNo" disabled
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item label="支付宝AppID：">
                                            <el-input class="inputW260" v-model="alipay.merchantAppId" disabled
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item label="支付公有密钥：">
                                            <el-input class="inputW260" v-model="alipay.merchantPublicKey" disabled
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item label="客户私有密钥：">
                                            <el-input class="inputW260" v-model="alipay.merchantPrivateKey" disabled
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" size="mini" @click="alipaySubmit">保存</el-button>
                                        </el-form-item>
                                    </div>
                                </el-form>
                            </div>
                        </el-tab-pane> -->
            <el-tab-pane label="会员支付参数" name="saobei">
              <div style="width: 800px" class="x-c">
                <el-form :model="saobei" ref="saobeiForm" label-width="200px">
                  <el-form-item label="是否启用">
                    <el-switch v-model="saobei.isStop"></el-switch>
                  </el-form-item>
                  <el-form-item label="限制支付会员级别:">
                    <el-select
                      v-model="payVipLevelIds"
                      multiple
                      class="inputW260"
                      placeholder="请选择"
                      @change="saobeiCannelChange"
                    >
                      <el-option
                        v-for="item in vipRankList"
                        :key="item.vipLevelId"
                        :label="item.vipLevelName"
                        :value="item.vipLevelId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="mini" @click="saobeiSubmit"
                      >保存</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import CButton from "@/views/components/c-button/index.vue";
import CardTitleCom from "@/views/components/cardTitleCom/index.vue";
import {
  payConfigDetail,
  payConfigSave,
  payConfigUpdate,
  alipayDetail,
  alipaySave,
  alipayUpdate,
  saobeiDetail,
  saobeiSave,
  saobeiUpdate,
  o2oMallPayDetail,
  o2oMallPayList,
  getDetail,
  saveOrUpdate,
} from "@/api/O2OMall/config/payConfig";

export default {
  name: "index",
  components: {
    CardTitleCom,
    CButton,
  },
  data() {
    return {
      weChatForm: {
        // 微信
        isStop: false, // 是否启用
        merchantAppId: "",
        merchantAppSecret: "",
        merchantNo: "",
        merchantSubAppId: "",
        merchantSubNo: "",
      },
      // 支付宝
      alipay: {
        isStop: false, // 是否启用
        merchantNo: "", // 支付宝账号
        merchantAppId: "", // 支付宝AppId
        merchantPublicKey: "", // 支付宝公钥
        merchantPrivateKey: "", // 支付宝私钥
      },
      // 扫呗
      saobei: {
        isStop: false, // 不启用
      },

      channelList: [], // 支付通道列表
      vipRankList: [], // 会员级别列表
      weChatCannel: "", // 微信支付通道
      alipayCannel: "", // 支付宝支付通道
      saobeiCannel: "", // 会员支付通道
      payVipLevelIds: null, // 会员级别列表
      payModeId: 6, // 支付方式Id
      isShowWeChat: false, // 是否显示微信通道信息
      isShowAlipayt: false, // 是否显示支付宝通道信息

      activeName: "weixin",
    };
  },
  watch: {
    channelList(nVal) {
      nVal.forEach((item) => {
        console.log("nVal：", item);
        if (this.payModeId == 6) {
          this.weChatForm = item;
          //   this.weChatForm.merchantAppId = item.merchantAppId;
          //   this.weChatForm.merchantAppSecret = item.merchantAppSecret;
          //   this.weChatForm.merchantNo = item.merchantNo;
          //   this.weChatForm.merchantSubAppId = item.merchantSubAppId;
          //   this.weChatForm.merchantSubNo = item.merchantSubNo;
          //   console.log("回显详情：", this.weChatForm);
        } else if (this.payModeId == 7) {
          this.alipay.merchantNo = item.merchantNo; // 支付宝账号
          this.alipay.merchantAppId = item.merchantAppId; // 支付宝AppId
          this.alipay.merchantPublicKey = item.merchantPublicKey; // 支付宝公钥
          this.alipay.merchantPrivateKey = item.merchantPrivateKey; // 支付宝私钥
          console.log("回显详情：", this.alipayCannel);
        } else if (this.payModeId == 3) {
        }
      });
    },
  },
  created() {
    this.getDetail();
    this.getChannelList();
    this.getO2OMallPayList();
  },
  methods: {
    // 支付通道
    getChannelList() {
      o2oMallPayDetail({
        payModeId: this.payModeId,
      }).then((res) => {
        this.channelList = res.data.data;
      });
    },
    getO2OMallPayList() {
      o2oMallPayList().then((res) => {
        console.log("会员级别", res);
        this.vipRankList = res;
      });
    },
    // 请求配置
    getDetail() {
      getDetail({
        payModeId: this.payModeId,
      }).then((res) => {
        if (res.data == null) return;
        if (this.payModeId == 6) {
          this.weChatCannel = res.data.payChannelId;
          this.weChatForm.isStop = res.data.isStop;
        } else if (this.payModeId == 7) {
          this.alipayCannel = res.data.payChannelId;
          this.alipay.isStop = res.data.isStop;
        } else if (this.payModeId == 3) {
          this.saobei.isStop = res.data.isStop;
          this.payVipLevelIds = res.data.payVipLevelIds.split(",");
        }
      });
    },
    WeChatSubmit() {
      saveOrUpdate({
        isStop: this.weChatForm.isStop,
        payChannelId: this.weChatCannel,
        payModeId: this.payModeId,
      }).then((res) => {
        this.$message.success("保存成功");
      });
    },
    alipaySubmit() {
      saveOrUpdate({
        isStop: this.alipay.isStop,
        payChannelId: this.alipayCannel,
        payModeId: this.payModeId,
      }).then((res) => {
        this.$message.success("保存成功");
      });
    },
    saobeiSubmit() {
      saveOrUpdate({
        isStop: this.saobei.isStop,
        payChannelId: this.saobeiCannel,
        payModeId: this.payModeId,
        payVipLevelIds: this.payVipLevelIds.toString(),
      }).then((res) => {
        this.$message.success("保存成功");
      });
    },
    // 微信支付通道改变
    weChatCannelChange(val) {
      console.log("微信支付通道改变:", val);
      this.isShowWeChat = true;
      this.channelList.forEach((item) => {
        console.log("微信支付通道改变item:", item);
        if (item.payChannelId == val) {
          this.weChatForm.merchantAppId = item.merchantAppId;
          this.weChatForm.merchantAppSecret = item.merchantAppSecret;
          this.weChatForm.merchantNo = item.merchantNo;
          this.weChatForm.merchantSubAppId = item.merchantSubAppId;
          this.weChatForm.merchantSubNo = item.merchantSubNo;
        }
      });
    },
    // 支付宝支付通道改变
    alipayCannelChange(val) {
      console.log("支付宝支付通道改变:", val);
      this.isShowAlipayt = true;
      this.channelList.forEach((item) => {
        if (item.payChannelId == val) {
          this.alipay.merchantNo = item.merchantNo; // 支付宝账号
          this.alipay.merchantAppId = item.merchantAppId; // 支付宝AppId
          this.alipay.merchantPublicKey = item.merchantPublicKey; // 支付宝公钥
          this.alipay.merchantPrivateKey = item.merchantPrivateKey; // 支付宝私钥
        }
      });
    },
    // 会员支付
    saobeiCannelChange() {},
    handleClick(val) {
      console.log("切换", val.name);
      if (val.name == "weixin") {
        this.payModeId = 6;
        this.getChannelList();
      } else if (val.name == "alipay") {
        this.payModeId = 7;
        this.getChannelList();
      } else if (val.name == "saobei") {
        this.payModeId = 3;
      }
      this.getDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;
  font-size: 13px;

  .fontColor {
    color: #949494;
  }

  .inputW260 {
    width: 400px;
  }
}
</style>
