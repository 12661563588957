import { render, staticRenderFns } from "./afterSaleSetting.vue?vue&type=template&id=33526d62&scoped=true"
import script from "./afterSaleSetting.vue?vue&type=script&lang=js"
export * from "./afterSaleSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33526d62",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-pre/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33526d62')) {
      api.createRecord('33526d62', component.options)
    } else {
      api.reload('33526d62', component.options)
    }
    module.hot.accept("./afterSaleSetting.vue?vue&type=template&id=33526d62&scoped=true", function () {
      api.rerender('33526d62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/O2OMall/config/sysConfig/components/afterSaleSetting.vue"
export default component.exports