var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "bg-Color marB10 x-f-end padd15" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.setMallInformation },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "marT10",
          attrs: { inline: false, model: _vm.form, rules: _vm.rules },
        },
        [
          _c("cardTitleCom", {
            attrs: { cardTitle: "品牌信息" },
            scopedSlots: _vm._u([
              {
                key: "cardContent",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "padd15" },
                      [
                        _c("span", {}, [
                          _vm._v(
                            "品牌信息将在商城主页面的页尾进行展示，优先展示品牌logo"
                          ),
                        ]),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "marT10",
                            attrs: {
                              label: "品牌名称",
                              "label-width": "80px",
                              prop: "mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_11",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value:
                                  _vm.form
                                    .mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_11,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_11",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_11",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "10px" } },
                          [
                            _c("accessory-upload", {
                              attrs: {
                                listType: "picture-card",
                                fileList: _vm.fileList,
                                limit: 1,
                                title: "品牌logo",
                                noneBtnImg:
                                  _vm.form
                                    .mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12 !=
                                    "" &&
                                  _vm.form
                                    .mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_BRAND_12 !=
                                    null,
                                explain: "仅支持格式JPG、PNG，建议尺寸330*90",
                              },
                              on: {
                                getFileItems: _vm.getFileItems,
                                delFileItems: _vm.deleteImg,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("cardTitleCom", {
            attrs: { cardTitle: "门店商城负责人信息" },
            scopedSlots: _vm._u([
              {
                key: "cardContent",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "padd15" },
                      [
                        _c("span", {}, [
                          _vm._v(
                            "为了方便顾客联系您，请填写门店商城负责人姓名和联系方式"
                          ),
                        ]),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "marT10",
                            attrs: {
                              label: "姓名",
                              "label-width": "80px",
                              prop: "mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_21",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value:
                                  _vm.form
                                    .mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_21,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_21",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_21",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "联系方式",
                              prop: "mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_22",
                              "label-width": "80px",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value:
                                  _vm.form
                                    .mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_22,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_22",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.mall_SYSTEM_CONFIG_MALL_SYS_SETTINGS_HEAD_22",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }